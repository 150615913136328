export const API = {
  // admin

  CREATE_COUPON: () => `admin/createCoupon`,
  GET_ALL_COUPON: () => `admin/getAllCoupons`,
  DELETE_COUPON: () => `admin/deleteCoupon`,
  CREATE_GIFT_CARD: () => `admin/createGiftCard`,
  GET_ALL_GIFT_CARD: () => `admin/getAllGiftCards`,
  DELETE_GIFT_CARD: () => `admin/deleteGiftCard`,
  REGISTER_USER: () => `admin/register`,
  LOGIN_USER: () => `admin/login`,
  LOGIN_REGISTER: () => `admin/register`,
  GET_ALL_BOOKINGS: () => `admin/getBooking/allBooking`,
  GET_BOOKING_BY_ID: () => `admin/getBooking/getById`,
  GET_BOOKING_BY_STATUS: () => `admin/getBooking/getByBookingStatus`,
  GET_BOOKING_BY_DATES: () => `admin/getBooking/getByBookingDates`,
  GET_BOOKING_BY_DATES_AND_STATUS: () =>
    `admin/getBooking/getByBookingDatesAndBookingStatus`,
  UPDATE_BOOKING_STATUS: () => `admin/update/bookingStatus`,
  UPDATE_BOOKING: () => `admin/booking/updateBooking`,
  DELETE_BOOKING: () => `admin/booking/deleteBooking`,
  CREATE_BOOKING: () => `admin/booking/createBooking`,
  ASSIGN_EMPLOYEE: () => `admin/booking/assignEmployee`,
  DISMISS_EMPLOYEE: () => `admin/booking/dismissEmployee`,
  REGISTER_EMPLOYEE: () => `admin/emp/registerEmployee`,
  GET_ALL_EMPLOYEE: () => `admin/emp/getAllEmployee`,
  DELETE_EMPLOYEE: () => `admin/emp/deleteEmployee`,
  UPDATE_EMPLOYEE: () => `admin/emp/updateEmployee`,
  GET_EMPLOYEE_BY_EMP_ID: () => `admin/emp/getEmployeeByEmpId`,
  GET_AVAILABLE_EMPLOYEES: () => `admin/booking/availableEmployees`,
  RESEND_CONFIRMATION_MESSAGE: () => `admin/booking/confirmation/resendMsg`,
  RESEND_CONFIRMATION_MAIL: () => `admin/booking/confirmation/resendMail`,
  RESEND_CONFIRMATION_EVENT: () => `admin/booking/confirmation/resendEvent`,
  REMINDER_MESSAGE: () => `admin/booking/reminder/msg`,
  REMINDER_MAIL: () => `admin/booking/reminder/mail`,
  NEWSLETTER: () => `admin/newsletter`,
  GET_BOOKING_SLOTS: () => `booking/getBookingSlots`,
  UPLOAD_IMAGE: () => `upload`,
  SEND_EMAIL: () => `admin/sendEmail`,
}

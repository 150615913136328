import axiosInstance from "../models/axiosInstance"
import { API } from "./apiUrls"

export const createCoupon = async (request) => {
  const response = await axiosInstance.post(API.CREATE_COUPON(), request)
  return response.data
}

export const getAllCoupon = async () => {
  const response = await axiosInstance.post(API.GET_ALL_COUPON())
  return response.data
}

export const deleteCoupon = async (request) => {
  const response = await axiosInstance.post(API.DELETE_COUPON(), request)
  return response.data
}

export const createGiftCard = async (request) => {
  const response = await axiosInstance.post(API.CREATE_GIFT_CARD(), request)
  return response.data
}

export const getAllGiftCard = async () => {
  const response = await axiosInstance.post(API.GET_ALL_GIFT_CARD())
  return response.data
}

export const deleteGiftCard = async (request) => {
  const response = await axiosInstance.post(API.DELETE_GIFT_CARD(), request)
  return response.data
}

export const userLogin = async (request) => {
  const response = await axiosInstance.post(API.LOGIN_USER(), request)
  return response.data
}

export const userRegister = async (request) => {
  const response = await axiosInstance.post(API.REGISTER_USER(), request)
  return response.data
}

export const getAllBookings = async (request) => {
  const response = await axiosInstance.post(API.GET_ALL_BOOKINGS(), request)
  return response.data
}

export const getBookingById = async (request) => {
  const response = await axiosInstance.post(API.GET_BOOKING_BY_ID(), request)
  return response.data
}

export const getBookingByStatus = async (request) => {
  const response = await axiosInstance.post(
    API.GET_BOOKING_BY_STATUS(),
    request
  )
  return response.data
}

export const getBookingByDates = async (request) => {
  const response = await axiosInstance.post(API.GET_BOOKING_BY_DATES(), request)
  return response.data
}

export const getBookingByDatesAndStatus = async (request) => {
  const response = await axiosInstance.post(
    API.GET_BOOKING_BY_DATES_AND_STATUS(),
    request
  )
  return response.data
}

export const updateBookingStatus = async (request) => {
  const response = await axiosInstance.post(
    API.UPDATE_BOOKING_STATUS(),
    request
  )
  return response.data
}

export const updateBooking = async (request) => {
  const response = await axiosInstance.post(API.UPDATE_BOOKING(), request)
  return response.data
}

export const deleteBookings = async (request) => {
  const response = await axiosInstance.post(API.DELETE_BOOKING(), request)
  return response.data
}

export const createBooking = async (request) => {
  const response = await axiosInstance.post(API.CREATE_BOOKING(), request)
  return response.data
}

export const assignEmployee = async (request) => {
  const response = await axiosInstance.post(API.ASSIGN_EMPLOYEE(), request)
  return response.data
}

export const dismissEmployee = async (request) => {
  const response = await axiosInstance.post(API.DISMISS_EMPLOYEE(), request)
  return response.data
}

export const registerEmployee = async (request) => {
  const response = await axiosInstance.post(API.REGISTER_EMPLOYEE(), request)
  return response.data
}

export const getAllEmployee = async (request) => {
  const response = await axiosInstance.post(API.GET_ALL_EMPLOYEE(), request)
  return response.data
}

export const deleteEmployee = async (request) => {
  const response = await axiosInstance.post(API.DELETE_EMPLOYEE(), request)
  return response.data
}

export const updateEmployee = async (request) => {
  const response = await axiosInstance.post(API.UPDATE_EMPLOYEE(), request)
  return response.data
}

export const getEmployeeByEmpId = async (request) => {
  const response = await axiosInstance.post(
    API.GET_EMPLOYEE_BY_EMP_ID(),
    request
  )
  return response.data
}

export const getAvailableEmployees = async (request) => {
  const response = await axiosInstance.post(
    API.GET_AVAILABLE_EMPLOYEES(),
    request
  )
  return response.data
}

export const resendConfirmationMessage = async (request) => {
  const response = await axiosInstance.post(
    API.RESEND_CONFIRMATION_MESSAGE(),
    request
  )
  return response.data
}

export const resendConfirmationMail = async (request) => {
  const response = await axiosInstance.post(
    API.RESEND_CONFIRMATION_MAIL(),
    request
  )
  return response.data
}

export const resendConfirmationEvent = async (request) => {
  const response = await axiosInstance.post(
    API.RESEND_CONFIRMATION_EVENT(),
    request
  )
  return response.data
}

export const reminderMessage = async (request) => {
  const response = await axiosInstance.post(API.REMINDER_MESSAGE(), request)
  return response.data
}

export const reminderMail = async (request) => {
  const response = await axiosInstance.post(API.REMINDER_MAIL(), request)
  return response.data
}

export const newsletter = async (request) => {
  const response = await axiosInstance.post(API.NEWSLETTER(), request)
  return response.data
}

//get slots
export const getBookingSlots = async (request) => {
  const response = await axiosInstance.post(API.GET_BOOKING_SLOTS(), request)
  return response.data
}

//upload Image
export const uploadImage = async (parms) => {
  const data = new FormData()
  for (let i = 0; i < parms.length; i++) {
    data.append("images", parms[i])
  }

  const response = await axiosInstance.post(API.UPLOAD_IMAGE(), data)
  return response.data
}

export const sendEmail = async (request) => {
  console.log(request, request)

  const response = await axiosInstance.post(API.SEND_EMAIL(), request)
  return response.data
}

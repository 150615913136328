import React, { useEffect, useRef, useState } from "react"
import { Header } from "../UI"
import DataTable from "react-data-table-component"
import JoditEditor from "jodit-react"
import { useDispatch, useSelector } from "react-redux"
import {
  AutoCompleteComponent,
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns"
import { handleSendEmailApi, SendMsgEmail } from "../data/modal/SendMsgEmail"
import { openToastify } from "../store/actions/toastifyAction"
import { REDUX_TOASTIFY_DEFAULTS } from "../store/config"
import ImageUpload from "../data/modal/image/ImageUpload"

const Marketing = () => {
  let { customerListData } = useSelector((state) => state.customerReducer)
  const editor = useRef(null)
  const [smsContent, setSmsContent] = useState("")
  const [isSenderData, setIsSenderData] = useState([])
  const [isloading, setIsloading] = useState(false)
  const [isData, setIsData] = useState({
    sendMsg: false,
    sendEmail: false,
    subject: "",
    message: "",
  })
  const dispatch = useDispatch()
  const [isMsgEmail, setIsMsgEmail] = useState(true)
  const [uploadImages, setUploadImages] = useState([])

  const handleNotification = (type, tittle, msg, to) => {
    const typeOfNotification = type //email or "sms"
    const subject = tittle
    const msgContent = msg
    const recipient = to
    // Call the SendMsgEmail function
    SendMsgEmail(typeOfNotification, subject, msgContent, recipient, dispatch)
  }

  const handleSend = () => {
    if (isData.subject === "" && isMsgEmail) {
      dispatch(
        openToastify(
          "Subject is Mendatory",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
        )
      )
      return
    }
    if (isData.message === "") {
      dispatch(
        openToastify(
          "Message is Mendatory",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
        )
      )
      return
    }

    if (isSenderData.length) {
      dispatch(
        openToastify(
          "Donot Leave or Refresh the page",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_INFO()
        )
      )
      setIsloading(true)
      const header = `
      <table width="100%" cellpadding="0" cellspacing="0" border="0" style="
              background: #020202;
              color: white;
              padding: 1rem;
              border-radius: 10px;
              height: 5rem;
            ">
            <tbody><tr>
              <td align="center">
                <img src="https://boostmobiledetailing.ca/wp-content/uploads/2024/01/boost-detailing-logo.png" alt="logo" width="80">
              </td>
            </tr>        
            </tbody></table>
            <br>
            `
      const makeImgDownloadable = (htmlString) => {
        // Create a temporary DOM element to parse the HTML string
        const tempDiv = document.createElement("div")
        tempDiv.innerHTML = htmlString

        // Process all <img> tags
        const imgElements = tempDiv.querySelectorAll("img")
        imgElements.forEach((imgElement) => {
          const imgUrl = imgElement.src

          // Create an <a> wrapper with a download attribute if not already wrapped
          if (
            !imgElement.parentElement ||
            imgElement.parentElement.tagName !== "A"
          ) {
            const downloadLink = document.createElement("a")
            downloadLink.href = imgUrl
            // downloadLink.download = imgUrl.split("/").pop() // Use the filename for download
            downloadLink.setAttribute("download", "") // Add the `download` attribute // Use the filename for download

            // Wrap the <img> element inside the <a> tag
            imgElement.parentNode.insertBefore(downloadLink, imgElement)
            downloadLink.appendChild(imgElement)
          } else {
            // If already wrapped in an <a>, ensure the download attribute is present
            const parentLink = imgElement.parentElement
            if (!parentLink.hasAttribute("download")) {
              // parentLink.download = imgUrl.split("/").pop()
              parentLink.setAttribute("download", "") // Add the `download` attribute
            }
          }
        })

        // Return the updated HTML as a string
        return tempDiv.innerHTML
      }

      isSenderData.forEach((customer, index) => {
        if (isMsgEmail) {
          const emails = isSenderData.map((customer) => {
            return customer?.email
          })

          console.log(
            makeImgDownloadable(isData.message),
            "makeImgDownloadable(isData.message)"
          )

          // handleSendEmailApi(
          //   emails,
          //   isData.subject,
          //   header + isData.message,
          //   uploadImages[0]
          // )

          handleNotification(
            "email",
            isData.subject,
            header + makeImgDownloadable(isData.message),
            customer?.email
          )
        } else {
          handleNotification(
            "sms",
            isData.subject,
            isData.message,
            customer?.phoneNumber
          )
        }
        if (isSenderData.length === index + 1) {
          setTimeout(() => {
            setIsloading(false)
          }, 2000)
        }
      })
      // if (isData.sendMsg) {
      //   handleNotification(
      //     "sms",
      //     isData.subject,
      //     smsContent,
      //     customer?.phoneNumber
      //   )
      // }
      // if (isData.sendEmail) {
      //   if (customer?.email) {
      //     handleNotification(
      //       "email",
      //       isData.subject,
      //       isData.message,
      //       customer?.email
      //     )
      //   }
      // } else {
      //   console.log("set isData.sendMsg || isData.sendEmail")
      //   dispatch(
      //     openToastify(
      //       "Select Message and Sms",
      //       REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
      //     )
      //   )
      // }

      setIsData((prevData) => ({
        ...prevData,
        subject: "",
        message: "",
      }))
    } else {
      console.log("select receiver")
      dispatch(
        openToastify(
          "Sender Details are Mendatory",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
        )
      )
    }

    setTimeout(() => {
      setIsloading(false)
    }, 2000)
  }

  // function removeHTMLTags(str) {
  //   return str.replace(/<\/?[^>]+(>|$)/g, "")
  // }
  useEffect(() => {
    setIsData((prevData) => ({
      ...prevData,
      message: "",
    }))
    console.log(isData, "isData")
    // if (isData.sendMsg) {
    //   const sms = removeHTMLTags(isData.message)
    //   setSmsContent(sms)
    // }
  }, [isMsgEmail])

  // Function to find customers by phone numbers
  const findCustomersByPhoneNumbers = (phoneNumbers) => {
    return customerListData?.filter((customer) =>
      phoneNumbers.includes(customer?.phoneNumber)
    )
  }
  const onChange = (args) => {
    console.log(args.target.value)
    const customers = findCustomersByPhoneNumbers(args.target.value)
    console.log("customers", customers)
    setIsSenderData(customers)
  }

  //Further can be used uploadImages State
  const handleImage = (images) => {
    console.log("images", images)
    setUploadImages(images)
  }

  // Custom template function to include both service name and price
  const customTemplate = (data) => (
    <div className="space-x-2">
      <span className="email">{data.email}</span>
      <span className="phoneNumber">{data.phoneNumber}</span>
    </div>
  )
  return (
    <>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl pb-5">
        <Header category="Page" title="Marketing" />
        {/* <DataTable
          // title="Coupons List"
          columns={mainColumns}
          data={Users}
          pagination
          fixedHeader
          selectableRows
          fixedHeaderScrollHeight="480px"
        /> */}
        <div className="pb-2 space-x-3">
          <button
            className={`${
              isMsgEmail
                ? "bg-yellow-light text-white"
                : "text-black border-black border-2"
            }  h-8 px-3 font-medium rounded-lg hover:shadow-md`}
            onClick={() => {
              setIsMsgEmail(true)
            }}
          >
            Send Email
          </button>
          <button
            className={`${
              isMsgEmail
                ? "text-black border-black border-2"
                : "bg-yellow-light text-white"
            } h-8 px-3 font-medium rounded-lg hover:shadow-md`}
            onClick={() => {
              setIsMsgEmail(false)
            }}
          >
            Send SMS
          </button>
        </div>

        <div className="overflow-hidden flex flex-col">
          <span>Select Receivers</span>
          <MultiSelectComponent
            placeholder="Select Recipient"
            dataSource={customerListData ?? []}
            width={300}
            value={null}
            fields={{
              text: "phoneNumber",
              value: "phoneNumber",
            }}
            mode="CheckBox"
            showSelectAll={true}
            // showDropDownIcon={true}
            // enableSelectionOrder={true}
            popupHeight="350px"
            itemTemplate={customTemplate}
            onChange={onChange}
          >
            <Inject services={[CheckBoxSelection]} />
          </MultiSelectComponent>
        </div>

        {isMsgEmail ? (
          <div
            className={`mt-3 duration-200 delay-75 ${
              isMsgEmail ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div>
              <span>Subject</span>
              <textarea
                className="border border-gray-300 w-full p-1"
                name="subject"
                id="subject"
                value={isData.subject}
                placeholder="Enter the subject"
                onChange={(e) => {
                  setIsData((prevData) => ({
                    ...prevData,
                    subject: e.target.value,
                  }))
                }}
              ></textarea>
            </div>
            <JoditEditor
              ref={editor}
              value={isData.message}
              onChange={(newContent) => {
                setIsData((prevData) => ({
                  ...prevData,
                  message: newContent,
                }))
              }}
            />
          </div>
        ) : (
          <div
            className={`mt-3 duration-200 delay-75 ${
              isMsgEmail ? "-translate-x-full" : "translate-x-0"
            }`}
          >
            <div>
              <span>Enter SMS</span>
              <textarea
                className="border border-gray-300 w-full p-1 h-28"
                name="subject"
                id="subject"
                value={isData.message}
                placeholder="Enter SMS"
                onChange={(e) => {
                  setIsData((prevData) => ({
                    ...prevData,
                    message: e.target.value,
                  }))
                }}
              ></textarea>
            </div>
          </div>
        )}

        <div>
          <ImageUpload imageURLS={handleImage} />
        </div>

        <div className="mt-3 space-y-2">
          {/* <div className="flex space-x-3">
            <div className="space-x-2">
              <input
                className="mt-1"
                type="checkbox"
                name="sendEmail"
                id="sendEmail"
                checked={isData?.sendEmail}
                onChange={(e) => {
                  setIsData((item) => ({
                    ...item,
                    sendEmail: e.target.checked,
                  }))
                }}
              />
              <label htmlFor="sendEmail">Send Email?</label>
            </div>
            <div className="space-x-2">
              <input
                className="mt-1"
                type="checkbox"
                name="sendMsg"
                id="sendMsg"
                checked={isData?.sendMsg}
                onChange={(e) => {
                  setIsData((item) => ({
                    ...item,
                    sendMsg: e.target.checked,
                  }))
                }}
              />
              <label htmlFor="sendMsg">Send SMS?</label>
            </div>
          </div> */}
          <button
            className="flex bg-yellow-light py-1 px-4 items-center"
            onClick={() => {
              handleSend()
            }}
            disabled={isloading}
          >
            {isloading ? <span>SENDING...</span> : <span>SEND</span>}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  )
}

export default Marketing
